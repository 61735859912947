<template>
  <section class="form">
    <FeaturedColPartForm />
    <article class="form-content form-info mt-1">
      <HeaderComponentPartForm
        title="Hora de crear tu contraseña"
        description="Asegúrate que sea segura"
        type="password"
      />
      <NewPasswordForm :token="id" />
    </article>
  </section>
</template>
<script>
import NewPasswordForm from '@/modules/auth/views/new-password/forms/NewPasswordForm';

import {
  FeaturedColPartForm,
  HeaderComponentPartForm,
} from '@/modules/auth/components/form-parts';

import { onBeforeMount } from 'vue';
import useAuthentication from '@/modules/auth/composables/authentication/useAuthentication';

export default {
  components: {
    FeaturedColPartForm,
    HeaderComponentPartForm,
    NewPasswordForm,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup() {
    onBeforeMount(() => {
      const { validateAuthentication } = useAuthentication();
      validateAuthentication('new-password');
    });
  },
};
</script>
